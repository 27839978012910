.SequentialTicket {
  margin-top: 5px;
  .ticket-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .ticket {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0 0;
      width: 100%;
      margin: 0;
      color: #fff;
      background: $gray-600;
      opacity: 1;
      font-size: 40px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      transition: all 0.3s ease;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        width: 48%;
        margin: 1%;
      }
      @include media-breakpoint-up(lg) {
        width: 31%;
        padding: 20px 0 0;
        font-size: 50px;
      }

      @include media-breakpoint-down(sm) {
        & + .ticket {
          margin-top: 5px;
        }
      }

      div {
        display: flex;
        width: 100%;
      }

      .ticket__mesa_descricao {
        padding: 0 10px 10px;
        @include media-breakpoint-up(lg) {
          padding-bottom: 20px;
        }
        .ticket__descricao {
          text-align: right;
          width: 100%;
        }

        .ticket__mesa {
          margin-right: 10px;
          max-width: 13%;
          strong {
            font-size: 40px;
            letter-spacing: -3px;
            font-weight: 700;
          }
        }
      }

      .ticket__col {
        flex-direction: column;
        align-items: center;
      }
      .ticket__row {
        flex-direction: row;
      }

      .ticket__nome {
        line-height: 1;
        font-size: 0.45em;
        color: #fff;
        width: 100%;
        margin-bottom: 5px;
        @media (min-width: 768px) and (max-width: 1366px) {
          font-size: 0.3em;
        }
      }
      .ticket__tipo {
        font-size: 12px;
        opacity: 0.5;
        font-weight: normal;
      }
      .ticket__data {
        text-align: right;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        span {
          margin-left: 5px;
        }
        strong {
          font-size: 0.4em;
          font-weight: 500;
          margin-left: 2px;
        }
      }
      .ticket__corretor {
        background: rgba(#000, 0.4);
        padding: 10px;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
        @include media-breakpoint-up(lg) {
          padding-bottom: 20px;
        }
        .ticket__nome__corretor {
          width: auto;
          font-size: 0.4em;
          margin-left: 10px;
          @media (min-width: 768px) and (max-width: 1366px) {
            font-size: 0.3em;
          }
        }
      }

      &.novo {
        opacity: 1;
        animation: novoAtendimento 1s linear infinite;
      }

      &.reservou {
        opacity: 1;
        animation: reservouLote 1s linear infinite;
      }

      &.chamou {
        opacity: 1;
        animation: chamarCorretor 1s linear infinite;
      }
    }
  }
}

@keyframes novoAtendimento {
  0% {
    background: $gray-500;
  }

  50% {
    background: #4bf15b;
  }
}

@keyframes reservouLote {
  0% {
    background: $gray-500;
  }

  50% {
    background: #f1e14b;
  }
}

@keyframes chamarCorretor {
  0% {
    background: $gray-500;
  }

  50% {
    background: #f14b4f;
  }
}
