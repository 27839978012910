.Login{
    background-image: url($images-path+'/caculator-bg.jpg');
    background-size: cover;
    background-color: #515159;
    background-position: 50%;
    height: 100%;

    @include media-breakpoint-up(lg){
        display: flex;
        padding: 0;
        .Login__right{
            padding-right: 0;
        }
    }

    .card{
        position: relative;
        border-radius: 0;
        border: 0;
        @extend .shadow-lg;

        .Login__card-header{
            small{
                font-size: 0.8rem;
            }
        }

        &::before{
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background: $revo-blue;
            background: linear-gradient(90deg, $revo-green 0%, $revo-blue 100%);
        }

        @include media-breakpoint-up(lg){
            height: 100%;
            display: flex;
            flex-flow: center;
            justify-content: center;
            .card-body{
                width: 60%;
                display: flex;
                justify-content: center;
                flex-flow: column;
            }
            &::before{
                width: 5px;
                left: -3px;
                height: 100%;
                background: linear-gradient($revo-green 10%, $revo-blue 100%);
            }
        }
    }
    
    &__header{
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg){
            position: absolute;
            width: 15rem;
            left: -16rem;
            height: 100%;
            top: 0;
            margin: 0;
            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        .Login__logo{
            width: 11rem;
            margin-top: 3rem;
            @include media-breakpoint-up(lg){
                margin-top: 0;
            }
        }
    }
}