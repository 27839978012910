.btn-center {
  margin: auto;
  display: block;
}

.btn-estornar {
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.item_detalhes-solicitacao {
  background: #ebf5ff;
}
