.SelectEmpreendimentoProspecto__list {
  position: relative;

  .list-group-item {
    padding: 0;
  }

  .Empreendimento__item {
    .Empreendimento__detalhes-wrapper {
      flex: 10;
      @include media-breakpoint-up(lg) {
        flex: 11;
      }
      @include media-breakpoint-up(xl) {
        flex: none;
      }
      .Empreendimento__detalhes {
        padding: 0.55rem 0.65rem;

        p {
          font-size: 0.9rem;
          cursor: pointer;
          &:hover {
            color: $primary;
          }
        }
      }
    }

    &.Empreedimento__etiqueta-contador {
      @extend .arrow-top-right;
      &::after {
        border-width: 29px;
        top: -20px;
        right: -4px;
        border-left-color: $primary;
      }
    }

    .Empreendimento__lista-detalhes {
      li {
        font-size: 0.7rem;
        line-height: 1;
        small {
          font-size: 0.7em;
        }
      }
    }
  }

  .Empreendimento__item__actions {
    flex: 5;
    @include media-breakpoint-up(lg) {
      flex: none;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 0.65rem;
    }
    &.Empreendimento__item__action--cancelar {
      min-width: 100px;
    }

    .Empreendimento__action-reservar {
      flex: 1;
      @include media-breakpoint-up(lg) {
        flex: none;
      }
      .btn {
        height: 50%;
        @include media-breakpoint-up(lg) {
          height: auto;
        }
      }
    }

    .Empreendimento__action-adicionar {
      flex: 2;
      @include media-breakpoint-up(lg) {
        flex: none;
      }
    }

    .btn {
      border-radius: 0;
      border-color: $gray-200;
      span {
        font-size: 0.8rem;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 0.4rem;
      }
      @include media-breakpoint-up(lg) {
        border-color: transparent;
      }

      &.Empreendimtno__btn-adicionar {
        svg {
          top: 30%;
          position: relative;
          @include media-breakpoint-up(lg) {
            position: static;
          }
        }
      }
    }
  }

  .Empreendimento__contador {
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 10;
    display: block;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    color: $white;
    width: 23px;
  }
}
