.tri-empreendimento-imob-corretor {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.logo-emprendimento-pesquisa {
  padding: 0 25px;
  img {
    height: 40px;
    margin-right: 15px;
  }
}

.logo-imobiliaria-pesquisa {
  padding: 0 25px;
  img {
    height: 55px;
  }
}

.foto-corretor-pesquisa {
  padding: 0 25px;
  img {
    height: 55px;
    border-radius: 50%;
  }
}
.dados-pesquisa-posvenda {
  ul {
    margin: 0;
    li {
      width: auto;
      margin: 0 20px;
    }
  }
}

.df-center {
  justify-content: space-between;
  align-items: center;
}

.align-icon-link {
  margin-top: -7px;
}
