.ProdutividadePage {
  .Loading {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.produtividade__detalhes-venda {
  .produtividade-imobiliaria-atend {
    ul {
      flex-direction: column;
    }
    ul li {
      width: auto;
      margin: 5px;
      padding: 0 5px;
      strong {
        font-size: 26px;
      }
    }
  }
}

.produtividade {
  margin: 0;
  li {
    line-height: 1;
    margin: 10px 10px;
    @include media-breakpoint-up(md) {
      margin: 10px 0;
    }
    small {
      color: #999999;
    }
    strong > small {
      color: #000;
    }
    img {
      max-width: 39px;
      border-radius: 50%;
    }
  }
}

.produtividade-card-resumo {
  li {
    margin: 5px 0;
    @include media-breakpoint-down(md) {
      margin: 5px 20px;
    }
  }
}

.CardProdutividadeResumo {
  margin-top: 25px;
  margin-bottom: 15px;
  .card {
    border: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    @include media-breakpoint-up(md) {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    }
    margin-bottom: 10px;
    .card-body {
      padding: 10px;
    }
    @media (min-width: 1599px) {
      padding: 15px 10px;
    }
    .produtividade-icon {
      margin-bottom: 0px;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ebebeb;
      position: relative;
      top: auto;
      right: auto;
      margin: auto;
      display: flex;

      &.responsive {
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }

      &.responsive {
        @include media-breakpoint-down(lg) {
          position: absolute;
          top: 5px;
          right: 10px;
          height: auto;
          width: auto;
          background-color: transparent;
        }
      }
    }
  }
  .produtividade-resumo {
    @extend .list-unstyled;
    display: flex;
    flex-direction: column;

    li {
      text-align: center;

      strong,
      small {
        display: block;
      }

      small {
        font-size: 12px;
      }
      strong {
        span {
          font-size: 0.5em;
          font-weight: normal;
        }
      }

      &.Destaque {
        font-size: 20px;
        small {
          font-size: 0.5em;
        }
        @include media-breakpoint-up(xl) {
          font-size: 26px;
          small {
            font-size: 12px;
          }
        }
        @media (min-width: 1599px) {
          font-size: 35px;
        }
        @media (min-width: 1800px) {
          font-size: 40px;
        }
      }
    }
    width: 100%;

    .ul-inline {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}

.produtividade-imobiliaria {
  div + div {
    @include media-breakpoint-up(md) {
      border-left: 1px solid #ebebeb;
    }
  }
  & > div {
    padding: 10px;
  }
  li {
    @include media-breakpoint-up(lg) {
      width: 33%;
    }
  }

  .miniaturas {
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      align-items: initial;
    }
  }
}

.produtividade-imobiliaria-atend {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    width: 100%;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    li {
      width: 50%;
      padding-left: 10px;
      strong {
        font-size: 26px;
      }
    }
  }
  .produtividade__detalhes-venda {
    ul {
      flex-direction: column;
    }
    ul li {
      width: auto;
      margin: 5px;
      padding: 0 5px;
      strong {
        font-size: 26px;
      }
    }
  }
}

.produtividade-imobiliaria-corr {
  div + div {
    @include media-breakpoint-up(md) {
      border-left: 1px solid #ebebeb;
    }
  }
  & > div {
    padding: 10px;
  }
  li {
    @include media-breakpoint-up(lg) {
      width: 24%;
    }
  }

  @include media-breakpoint-up(md) {
    justify-content: start;
  }
  li {
    margin: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #ebebeb;
  }
}

.img-imobiliaria {
  width: 100%;
  max-width: 80px;
  margin: 10px;
  @include media-breakpoint-up(lg) {
    margin: auto 25px;
    max-width: 99px;
  }
}

.flag-ranking {
  z-index: 99;
  width: 27px;
  height: 27px;
  box-sizing: content-box;
  padding-top: 3px;
  left: 3px;
  top: 0;
  position: absolute;
  background: #0744a0;
  color: white;
  font-size: 10px;
  text-align: center;
}

.flag-ranking:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 13px solid #fff;
  border-left: 13px solid transparent;
  border-right: 14px solid transparent;
}

.CardProdutividadeFiltro {
  .card {
    border: 0;
    @extend .shadow-sm;
  }
}

.ImobiliariaItem {
  & + .ImobiliariaItem {
    margin-top: 20px;
  }
  .ImobiliariaItemResumo {
    margin-top: 10px;
  }
  .produtividade-imobiliaria-atend {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.CardCorretoresEmpreendimentos {
  .btnTransparente {
    height: auto;
  }
  .produtividade__detalhes-venda {
    .produtividade-imobiliaria-atend ul {
      flex-direction: row;
    }
    .produtividade {
      justify-content: space-evenly;
      li {
        margin: 10px 5px;
      }
    }
  }
}

.img-resumo-imobiliaria {
  width: 80px;
  font-size: 9px;
  img {
    width: 100%;
    max-width: 70px;
    border-radius: 10%;
  }
}

.CardDetalhesVenda {
  .resumo-vendas {
    border-top: 1px solid #ebebeb;
    padding: 10px 15px 15px;
    table tbody tr {
      border-color: transparent;
      & + tr {
        border-top-color: #ebebeb;
      }
    }
  }
  .produtividade__detalhes-venda {
    .produtividade-imobiliaria-atend ul {
      flex-direction: row;
    }
    .produtividade {
      justify-content: space-evenly;
      li {
        margin: 10px 5px;
        @include media-breakpoint-up(lg) {
          width: auto;
          margin: 0 20px;
        }
      }
    }
  }
  .linha-resumo-imobiliaria {
    .nome-selecionado {
      text-align: center;
      display: block;
      font-size: 1.5em;
      margin-bottom: 20px;
    }
  }
}

.produtividade-imob-corr {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  svg {
    font-size: 1.2em;
    color: #ccc;
    margin: 0 5px;
  }

  .btnTodos {
    height: 50px;
    font-size: 1.3em;
    @include media-breakpoint-up(md) {
      font-size: 1.5em;
    }
  }

  .imobiliariaSelecionada {
    height: 40px;
    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .empreendimentoSelecionado {
    img {
      height: 50px;
    }
  }
  .corretorSelecionado {
    img {
      height: 50px;
      border-radius: 50%;
    }
  }
}

.corretorSelecionado {
  flex-direction: column;
}

.btnTransparente {
  outline: transparent !important;
  background: transparent;
  border: 0;
  height: 50px;
}

.nomeEmpreendimentoCorretor {
  margin-left: 35px;
  font-weight: 700;
}

.cardHover:hover {
  cursor: pointer;
  box-shadow: 0 0 0 3px #007bff99;
}
