.VinculadorSenhaLancamento {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: -85px;
    right: 25px;
    margin: 0;

    .VinculadorSenhaLancamento__wrapper-editar {
      margin-top: 15px;
    }
  }

  label {
    margin-bottom: 0;
  }
  input {
    width: 100px;
  }
}
