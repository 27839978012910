.have-search {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background: $primary;
    right: 5px;
    top: 5px;
  }
}
