.ProspectoDetalhes {
  padding-bottom: 6rem;
  .card-header-pills {
    .nav-tabs,
    .nav-pills {
      overflow-x: auto;
      overflow-y: hidden;
      display: -webkit-box;
      display: -moz-box;

      &::-webkit-scrollbar {
        display: none;
      }

      max-width: 320px;
      @include media-breakpoint-up(sm) {
        max-width: 768px;
      }
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }
    .nav-tabs > li {
      float: none;
    }

    .nav-item .NavLink__active {
      background: #ccc;
      color: #000;
    }
  }

  .fixed-bottom {
    .actions {
      border-top: 1px solid $gray-200;
    }
  }

  .ProspectDetails__bottom {
    position: fixed;
    bottom: 0;
    z-index: 1010;
    background: #fff;
    border-top: 1px solid #eee;
    padding: 10px;
    width: 100%;
    left: 0;

    @include media-breakpoint-up(lg) {
      padding: 0;
      border-top-color: transparent;
      position: relative;
      bottom: auto;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: auto;

      background: transparent;
      width: auto;
    }

    .FormDadosPessoais__error {
      font-size: 12px;

      @include media-breakpoint-up(lg) {
        padding: 0 0.2rem 0.5rem;
        height: 18px;
        position: absolute;
        bottom: -22px;
        right: 0;
        white-space: nowrap;
        text-align: right;
        padding: 0;
      }
    }
  }
}
