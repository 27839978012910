.caixa-edicao {
  height: 440px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

/* precisa dessas estilização para fazer funcionar os nomes dos tamanhos personalizados dentro da tollbar do QuillJS */
/*Eu consegui resolver esse problema dentro de uma issue da propria lib https://github.com/slab/quill/issues/1208*/
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-container {
  height: 90% !important;
}
