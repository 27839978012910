.slider-section {
  margin-bottom: 20px; /* Espaçamento entre os sliders */
}

.slider-section h5 {
  margin-bottom: 10px; /* Espaçamento entre o título e o slider */
}

.input-range__label {
  color: #000; /* Labels em preto para maior contraste */
}

.input-range__label--value {
  top: -30px; /* Ajustar posição das labels */
  font-weight: bold; /* Tornar mais visível */
}

.input-range__slider-container .input-range__label--value {
  position: absolute;
  top: -40px;
  transform: translateX(-50%);
  font-weight: bold;
  white-space: nowrap;
}
