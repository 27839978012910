.CamposDocumentos{
    .CardDocumento{
        background: $gray-200;
        border-radius: $border-radius;

        & > div{
            padding: 0.7rem;
        }

        .CardDocumento__descricao{
            line-height: 1;

        }

        .CardDocumento__img{
            width: 50px;
            height: 50px;
            background-size: cover;
            background-position: 50%;
            background-color: #ebebeb;
            overflow: hidden;
            border: 1px solid #ddd;

            &:hover{
                cursor: pointer;
                border-color: $primary;
            }

            .react-pdf__Document{
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                &,
                .react-pdf__Page{
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
                .react-pdf__Page{
                    .react-pdf__Page__canvas{
                        width: 100% !important;
                        height: auto !important;
                        position: absolute;
                        left: -100%;
                        right: -100%;
                        top: -100%;
                        bottom: -100%;
                        margin: auto;
                    }
                }
            }
        }

        .CardDocumento__btn-remove{
            color: #bbb;
            margin-left: 1rem;
            margin-right: 1rem;
            &:hover{
                cursor: pointer;
                color: $primary;
            }
        }

        &.CardDocumento--vazio{
            line-height: 1;
            color: $gray-500;
            background-color: transparent;
            
            .dropzone{
                width: 100%;
                border-radius: $border-radius;
                border: 1px dashed $gray-200;
                &:hover{
                    border: 1px solid $black;
                    .svg-inline--fa path{
                        fill: $black;
                    }
                }
                &,
                &:hover,
                &:focus{
                    outline: transparent;
                }
                &.dropzone--is-active{
                    border: 1px solid $primary;
                    .dropzone__label{
                        .dropzone__label__wrapper{
                            .dropzone__label__legend{
                                strong{
                                    color: $primary;
                                }
                            }
                            .svg-inline--fa path{
                                fill: $primary;
                            }
                        }
                    }
                }
            }


            &:hover{
                border-color: $black;
                cursor: pointer;
                .dropzone__label{
                    .dropzone__label__wrapper{
                        .dropzone__label__legend{
                            span,
                            strong{
                                color: $black;
                            }
                        }
                    }
                }
            }

            .dropzone__label{
                display: flex;
                @include media-breakpoint-up(sm){
                    height: 40px;
                }
                .dropzone__label__wrapper{
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: space-between;
                    .dropzone__label__legend{
                        span{
                            font-size: 0.8rem;
                        }
            
                        strong{
                            color: $gray-600;
                        }
            
                        strong,
                        span{
                            display: block;
                        }
                    }
                    .dropzone__label__icon{
                        margin-left: 1rem;
                        margin-right: .7rem;
                    }
                }
            }
        }
    }
}