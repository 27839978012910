.dados-pesquisa-posvenda {
  ul {
    margin: 0;
    li {
      width: auto;
      margin: 0 20px;
    }
  }
}

.df-center {
  justify-content: space-between;
  align-items: center;
}

.align-icon-link {
  margin-top: -7px;
}

.CardDetalhesVenda {
  .resumo-vendas {
    border-top: 1px solid #ebebeb;
    padding: 10px 15px 15px;
    table tbody tr {
      border-color: transparent;
      & + tr {
        border-top-color: #ebebeb;
      }
    }
  }
  .produtividade__detalhes-venda {
    .produtividade-imobiliaria-atend ul {
      flex-direction: row;
    }
    .produtividade {
      justify-content: space-evenly;
      li {
        margin: 10px 5px;
        @include media-breakpoint-up(lg) {
          width: auto;
          margin: 0 20px;
        }
      }
    }
  }
  .linha-resumo-imobiliaria {
    .nome-selecionado {
      text-align: center;
      display: block;
      font-size: 1.5em;
      margin-bottom: 20px;
    }
  }
}

.ImobiliariaItem {
  & + .ImobiliariaItem {
    margin-top: 20px;
  }
  .ImobiliariaItemResumo {
    margin-top: 10px;
  }
  .produtividade-imobiliaria-atend {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.flag-ranking {
  z-index: 99;
  width: 27px;
  height: 27px;
  box-sizing: content-box;
  padding-top: 3px;
  left: 3px;
  top: 0;
  position: absolute;
  background: #0744a0;
  color: white;
  font-size: 10px;
  text-align: center;
}

.produtividade__detalhes-venda {
  .produtividade-imobiliaria-atend {
    ul {
      flex-direction: column;
    }
    ul li {
      width: auto;
      margin: 5px;
      padding: 0 5px;
      strong {
        font-size: 26px;
      }
    }
  }
}

.produtividade {
  margin: 0;
  li {
    line-height: 1;
    margin: 10px 10px;
    @include media-breakpoint-up(md) {
      margin: 10px 0;
    }
    small {
      color: #999999;
    }
    strong > small {
      color: #000;
    }
    img {
      max-width: 39px;
      border-radius: 50%;
    }
  }
}

.produtividade-imobiliaria {
  div + div {
    @include media-breakpoint-up(md) {
      border-left: 1px solid #ebebeb;
    }
  }
  & > div {
    padding: 10px;
  }
  li {
    @include media-breakpoint-up(lg) {
      width: 33%;
    }
  }

  .miniaturas {
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      align-items: initial;
    }
  }
}

.produtividade-imobiliaria-atend {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    width: 100%;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    li {
      width: 50%;
      padding-left: 10px;
      strong {
        font-size: 26px;
      }
    }
  }
  .produtividade__detalhes-venda {
    ul {
      flex-direction: column;
    }
    ul li {
      width: auto;
      margin: 5px;
      padding: 0 5px;
      strong {
        font-size: 26px;
      }
    }
  }
}


