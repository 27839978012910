.LancamentoSequenciaAtendimento {
  background: #111;
  padding-top: 20px;
  min-height: 100%;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    background: #2499ff;
    width: 10px;
    display: block;
    content: '';
    bottom: 0;
    box-shadow: 0 2px 10px rgba(#2499ff, 0.3);
    transition: all 1s linear;
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .Titulo {
      color: #fff;
      font-size: 20px;
      margin: 0;
      line-height: 1;
    }
    img {
      height: 20px;
    }
  }

  .Loading {
    color: #fff;
    font-size: 30px;
  }

  .ListaCorretores {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;

    & > li {
      background: #fff;
      position: relative;
      border-radius: 2px;
      border: 0;
      margin-bottom: 4px;
      padding: 4px 20px;
      line-height: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::before {
        display: block;
        content: '';
        width: 5px;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }

      &.EmEspera {
        background: #444;
        color: #fff;
        .Avatar {
          color: #111;
        }
      }

      .Descricao {
        display: flex;
        align-items: center;
        .Nome {
          font-size: 30px;
          font-weight: 700;
        }
        .Avatar {
          margin-right: 15px;
          height: 35px;
          width: 35px;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ebebeb;
          img {
            min-height: 100%;
            min-width: 100%;
          }
        }
      }
      .Contadores {
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          li {
            display: flex;
            align-items: center;
            margin-left: 15px;
            line-height: 1;
            small {
              font-size: 18px;
              opacity: 0.8;
            }
            strong {
              margin-left: 5px;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
