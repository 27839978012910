.table {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    display: block;
    width: 100%;
    text-align: left;
  }
  .table th {
    font-weight: bold;
  }
}

/* Regras específicas para mobile */
@media (max-width: 768px) {
  .ocultar-mobile {
    display: none;
  }
}

/* Regras padrão para telas maiores */
@media (min-width: 769px) {
  .pagination {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .pagination .page-item {
    flex: initial; /* Volta ao comportamento padrão no desktop */
  }
}

.font-size-14 {
  font-size: 14px;
}

.font-size-13 {
  font-size: 13px;
}
