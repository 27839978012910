.LancamentoSolicitacoes {
  h3 {
    text-align: center;
  }
  .col {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .solicitacao-list {
    padding: 0;
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    .solicitacao {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0 0;
      width: 100%;
      margin: 0;
      color: $gray-700;
      background: $white;
      border-radius: 2px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      opacity: 1;
      font-size: 40px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      transition: all 0.3s ease;
      justify-content: space-between;

      & + .solicitacao {
        margin-top: 8px;
      }

      div {
        display: flex;
        width: 100%;
      }

      .solicitacao__mesa_descricao {
        padding: 0 10px 10px;
        @include media-breakpoint-up(lg) {
          padding-bottom: 10px;
        }
        .solicitacao__descricao {
          text-align: right;
          justify-content: center;
          width: 100%;
        }

        .solicitacao__mesa {
          margin-right: 10px;
          max-width: 30%;
          @media (min-width: 1800px) {
            max-width: 13%;
          }
          strong {
            font-size: 40px;
            font-weight: 700;
            @media (min-width: 1800px) {
              font-size: 70px;
              margin-left: 40px;
            }
          }
        }
      }

      .solicitacao__col {
        flex-direction: column;
        align-items: center;
      }
      .solicitacao__row {
        flex-direction: row;
      }

      .solicitacao__nome {
        line-height: 1;
        width: 100%;
        margin-bottom: 5px;
        justify-content: flex-end;
        font-size: 0.5em;
        @media (min-width: 1800px) {
          font-size: 0.75em;
        }
      }
      .solicitacao__tipo {
        font-size: 12px;
        opacity: 0.5;
        font-weight: normal;
      }
      .solicitacao__data {
        text-align: right;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        span {
          margin-left: 5px;
        }
        strong {
          font-size: 0.5em;
          font-weight: 500;
          margin-left: 2px;
        }
      }
      .solicitacao__corretor {
        background: rgba(#000, 0.4);
        padding: 10px;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
        @include media-breakpoint-up(lg) {
          padding-bottom: 20px;
        }
        .solicitacao__nome__corretor {
          width: auto;
          font-size: 0.4em;
          margin-left: 10px;
          @media (min-width: 768px) and (max-width: 1366px) {
            font-size: 0.3em;
          }
        }
      }

      &.novo {
        opacity: 1;
        animation: novoAtendimento 1s linear infinite;
      }

      &.reservou {
        opacity: 1;
        animation: reservouLote 1s linear infinite;
      }

      &.chamou {
        opacity: 1;
        animation: chamarCorretor 1s linear infinite;
      }
    }
  }
}

@keyframes novoAtendimento {
  0% {
    background: $gray-500;
  }

  50% {
    background: #4bf15b;
  }
}

@keyframes reservouLote {
  0% {
    background: $gray-500;
  }

  50% {
    background: #f1e14b;
  }
}

@keyframes chamarCorretor {
  0% {
    background: $gray-500;
  }

  50% {
    background: #f14b4f;
  }
}
