.table-center {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  line-height: 1;
}

.ItemSubGrupo{
  label,
  label + input{
    flex: 1;    
  }
} 

#motivos td table {
  margin-top: 15px;
}

#motivos td input {
  margin-right: 10px;
  cursor: pointer;
}

#motivos td label {
  margin: 0 0 0 5px;
  cursor: pointer;
  display: block;
  flex: 1;
}
