.EscalaSatisfacao {
  .EscalaSatisfacao__btn {
    width: 8%;
    height: 32px;
    padding: 0;
    line-height: 1;
    margin: 0 0.5%;
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      padding: 0;
      line-height: 1;
      margin: 0 0.5%;
      font-size: 1.45rem;
    }
  }
}
