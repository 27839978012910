.Ocorrencias{
    .Ocorrencia{
        margin-bottom: 0.3rem;
        &.is-new,
        &.is-update{
            margin-top: 14px;
        }
        &.is-new{
            border-color: $success;
        }
        &.is-update{
            border-color: $primary;
        }
        &.is-active{
            .Ocorrencia__Texto{
                display: initial;
            }
            .Ocorrencia__Titulo{
                color: $primary;
                @include media-breakpoint-up(md){
                    color: initial;
                }
            }
        }
        .Ocorrencia__feedback{
            position: absolute;
            top: -12px;
            border-radius: 15px;
            padding: 0.1rem 1rem;
            right: 0;
            width: auto;
            height: 24px;
            font-size: 0.8rem;
        }
        .Ocorrencia__Titulo{
            cursor: pointer;
            @include media-breakpoint-up(md){
                cursor: default;
            }
        }
        .Ocorrencia__Texto{
            display: none;
            font-size: .9rem;
    
            @include media-breakpoint-up(md){
                display: initial;
            }
        }
        .Ocorencia__btn-editar{
            cursor: pointer;
        }
    }
}