// $input-border-width: 2px;
$images-path: './Images';
$revo-blue: #1e398d;
$revo-green: #50b848;
$height-ambiente: 15px;
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';
@import '~react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

.spinner {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

body,
html,
#root {
  height: 100%;
}

#root {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  background: #f5f6f7;
}

.App {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  user-select: none;
}

.line-height-1 {
  line-height: 1;
}

.navTop {
  background-color: #0052d3;
}

.z20 {
  z-index: 20;
}

$size: 10px;

.arrow {
  &::after,
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    position: absolute;
  }
}

.arrow-bottom-left {
  @extend .arrow;

  &:before {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $gray-200;
    transform: rotateZ(135deg);
    bottom: -($size - 3);
    left: -2px;
  }
}

.arrow-top-right {
  @extend .arrow;

  &:after {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $gray-200;
    transform: rotateZ(-45deg);
    top: -($size - 2);
    right: -2px;
  }
}

.comissoes.top {
  @extend .arrow-top-right;

  &::after {
    border-left-color: green;
  }
}

.comissoes.bottom {
  @extend .arrow-bottom-left;

  &::before {
    border-left-color: orange;
  }
}

.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.w-60 {
  width: 60%;
}

.invert-color {
  filter: invert(1);
}

.btn-mini-rounded {
  border-radius: 50%;
  transform: scale(0.65);
}

//Colors
$secondary: #001838;
$blue: #1d1e42;
$green: #5eaf30;
$sequential-ticket-bg: $gray-200;
$sequential-ticket-recents-bg: $gray-600;

// mixins
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin triangle(
  $color,
  $direction,
  $size: 6px,
  $position: absolute,
  $round: false
) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction ==down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction ==up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction ==right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction ==left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

.outline-none {
  &,
  &:focus {
    outline: 0;
  }
}

.react-select.is-invalid > div {
  border-color: #dc3545;
}

.no-hover:hover {
  background-color: transparent !important;
}

.z-index-1060 {
  z-index: 1060;
}

.button-area > button + button {
  @media (min-width: map-get($breakpoints, xs)) {
    margin-left: 8px;
  }
}

.card-small > .card-header {
  padding: 6px 10px;
}

.card-small > .card-body {
  padding: 10px;
}

.text-very-small {
  font-size: 0.7rem;
}

.w-sm-50 {
  @media (min-width: map-get($breakpoints, sm)) {
    width: 50% !important;
  }
}

.responsiveTable td.pivoted.no-pivot {
  padding-left: 0 !important;
  text-align: right !important;
}

.password {
  text-security: disc;
  -webkit-text-security: disc;
  -mox-text-security: disc;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.shadow-bottom {
  z-index: 999;
  -webkit-box-shadow: 0 8px 6px -6px #ececec;
  -moz-box-shadow: 0 8px 6px -6px #ececec;
  box-shadow: 0 8px 6px -6px #ececec;
}

// MAPA
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

// DEFAULTS
.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  fill: none;
}

.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-5,
.cls-8,
.cls-10,
.cls-11 {
  stroke: #fff;
}

.cls-6,
.cls-7,
.cls-9 {
  stroke: #000;
  fill: #cecece;
}

.cls-6,
.cls-7,
.cls-12,
.cls-13,
.cls-8,
.cls-9,
.cls-14,
.cls-15,
.cls-10 {
  fill-rule: evenodd;
}

.cls-6,
.cls-7,
.cls-12,
.cls-8,
.cls-9,
.cls-3,
.cls-10 {
  stroke-width: 0.4px;
}

.cls-6,
.cls-9 {
  stroke: #000200;
}

.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22 {
  isolation: isolate;
}

.cls-16,
.cls-17,
.cls-19 {
  font-family: ArialMT, Arial;
}

.cls-16,
.cls-20 {
  font-size: 24px;
}

.cls-7,
.cls-2,
.cls-4 {
  stroke: #000;
}

.cls-12 {
  fill: #fff;
  stroke: #1b1918;
}

.cls-2 {
  stroke-miterlimit: 4;
  stroke-width: 1px;
}

.cls-23 {
  fill: #1f721a;
}

.cls-13 {
  fill: #aa967b;
}

.cls-17,
.cls-22 {
  font-size: 28px;
}

.cls-8,
.cls-14 {
  fill: #63c8f2;
}

.cls-8,
.cls-3,
.cls-10 {
  display: none;
}

.cls-14 {
  fill-opacity: 0;
}

.cls-19 {
  font-size: 32px;
}

.cls-24 {
  fill: #706a6a;
}

.cls-20,
.cls-21,
.cls-22 {
  font-family: Arial-BoldMT, Arial;
  font-weight: 700;
}

.cls-3 {
  stroke: #100c33;
}

.cls-21 {
  font-size: 26px;
}

.cls-25 {
  fill: #353535;
}

.cls-10 {
  fill: #ee7114;
}

.cls-11 {
  fill: #1c9bff;
}

.cls-5 {
  fill: #ee7219;
}

.vendido {
  fill: #ff0000;
  // fill: #0847A6;
}

.reservado {
  fill: #ffdc15;
}

.bloqueado {
  fill: black;
}

.disponivelF1 {
  fill: #1c9bff;
}

.disponivelF2 {
  fill: #e97500;
}

.cls-5:hover,
.cls-11:hover {
  fill: #777;
}

.z-index-0 {
  z-index: 0;
}

//pills mapa
.contadores-mapa {
  border-style: solid;
  border-color: #cecece;
  border-width: thin;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  border-radius: 0.25rem;
}

.destacar-ativos-filtro {
  display: flex;
  opacity: 1;
  animation: notificando 1s linear infinite;
}

@keyframes notificando {
  0% {
    fill: #f600e6e5;
  }

  50% {
    fill: #ff00f7;
  }
}

.grupo-botoes-acoes-mapa {
  position: fixed;
  top: 85px;
  right: 27%;
  flex-direction: row;
  z-index: 1000;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.btn-acao-mapa {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
}

@import './Components/Login/Login';
@import './Components/Prospectos/ProspectoBusca/ProspectoBusca';
@import './Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
@import './Components/Prospectos/ProspectoDetalhes/CamposDocumentos/CamposDocumentos.scss';
@import './Components/Prospectos/ProspectoDetalhes/CamposDadosPessoais/CamposDadosPessoais.scss';
@import './Components/Prospectos/ProspectoDetalhes/CamposEndereco/CamposEndereco.scss';
@import './Components/Prospectos/ProspectoDetalhes/FormDadosPessoais.scss';
@import './Components/Prospectos/ProspectoDetalhes/SelectEmpreendimentoProspecto/SelectEmpreendimentoProspecto.scss';
@import './Components/Prospectos/ProspectoDetalhes/Ocorrencias/Ocorrencias.scss';
@import './Components/Prospectos/ProspectoDetalhes/VinculadorSenhaLancamento';
@import './Components/Comercial/Comprovantes/AnexarComprovante';
@import './Components/Produtividade/Produtividade.scss';
@import './Components/Atendimento/_AtendimentoCorretor.scss';
@import './Pages/LancamentoSolicitacoes/LancamentoSolicitacoes';
@import './Components/Prospectos/ProspectoDetalhes/HeaderProspectoDetalhes/HeaderProspectoDetalhes';
@import './Components/Prospectos/ProspectoDetalhes/HeaderProspectoDetalhes/HeaderProspectoDetalhes';
@import './Components/EscalaSatisfacao/EscalaSatisfacao';
@import './Pages/LancamentoAvaliacao/LancamentoAvaliacao';
@import './Pages/LancamentoSequenciaAtendimento/LancamentoSequenciaAtendimento';
@import './Pages/Proposta/PropostaEstorno';
@import './Styles/Ribbon';
@import './Styles/Divider';
@import './Pages/PesquisaPosVenda/styles.scss';
@import './Components/StatusSituacaoCliente/styles.scss';
@import './Pages/AdmVendas/AdmVendas';
