.FormDadosPessoais {
    position: relative;
    .is-error {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: .5em;
            height: .5em;
            border-radius: 50%;
            background: $danger;
            right: 5px;
            top: 5px;
        }
    }
}